import {GetStaticPaths, GetStaticProps} from 'next'

import {AppEnvType} from './_app'
import {Article} from 'nodes/Article'
import {Concept} from 'nodes/Concept'
import {Contact} from 'nodes/Contact'
import {DPFullNode} from 'types/DPTypes'
import {Event} from 'nodes/Event'
import {Gallery} from 'nodes/Gallery'
import {Page} from 'nodes/Page'
import {Product} from 'nodes/Product'
import {Program} from 'nodes/Program'
import {Project} from 'nodes/Project'
import React from 'react'
import {Roadmap} from 'nodes/Roadmap'
import {obfuscate} from 'util/obfuscate'
import {prefetchFullArticles} from 'blocks/fullwidth/fullarticles/prefetchFullArticles'
import {prefetchFullCalendar} from 'blocks/fullwidth/fullcalendar/prefetchFullCalendar'
import {prefetchFullProjects} from 'blocks/fullwidth/fullprojects/prefetchFullProjects'

const DrupalPage: React.FC<DPFullNode> = (data: DPFullNode) => {
	switch (data.type) {
		case 'gallery':
			return <Gallery {...data} />
		case 'roadmap':
			return <Roadmap {...data} />
		case 'program':
			return <Program {...data} />
		case 'page':
			return <Page {...data} />
		case 'event':
			return <Event {...data} />
		case 'concept':
			return <Concept {...data} />
		case 'project':
			return <Project {...data} />
		case 'article':
			return <Article {...data} />
		case 'product':
			return <Product {...data} />
		case 'contact':
			return <Contact {...data} />
	}
	return null
}
export default DrupalPage

export const getStaticPaths: GetStaticPaths = async () => {
	let data: Array<string> = []

	if (process.env.PRELOAD) {
		const res = await fetch(process.env.NEXT_PUBLIC_DRUPAL_URL + '/api/paths')
		data = await res.json()
	} else {
		const res = await fetch(
			process.env.NEXT_PUBLIC_DRUPAL_URL + '/api/prebuild-paths'
		)
		data = await res.json()
	}

	return {
		fallback: 'blocking',
		paths: data
			.filter((url) => url)
			.map((url) => ({
				params: {slug: url.split('/').slice(1)}
			}))
	}
}

export const getStaticProps: GetStaticProps = async (context) => {
	const pieces = context.params!.slug as string[]
	const slug = '/' + pieces.join('/')
	const lang = pieces[0] || 'nl'

	const nodeUrl =
		process.env.NEXT_PUBLIC_DRUPAL_URL +
		`/api/page?route=${encodeURIComponent(slug)}`
	const resNode = await fetch(nodeUrl)
	const nodeResponse = await resNode
	const txt = await nodeResponse.text()
	if (txt === 'No valid route supplied') return {notFound: true}
	const node: DPFullNode & {status: string; destination: string} =
		JSON.parse(txt)
	if (!node) return {notFound: true}

	if (node.status === 'redirect')
		return {
			redirect: {
				destination: node.destination,
				permanent: true
			},
			revalidate: 60 * 5
		}

	const resGeneral = await fetch(
		process.env.NEXT_PUBLIC_DRUPAL_URL + `/api/general/${lang}`
	)
	const general: any = await resGeneral.json()
	let revalidate = 60 * 2

	switch (node.type) {
		case 'article':
			if (node.internal_author) obfuscateContact(node.internal_author)
			break
		case 'contact':
			obfuscateContact(node)
			break
	}

	if ('sidebar' in node) {
		for (const block of node.sidebar) {
			switch (block.type) {
				case 'sidebar_contact':
					if (block.contact) {
						block.contact.forEach((contact) => obfuscateContact(contact))
					}
					break
				case 'sidebar_sf_contact':
					if (block.contact) obfuscateContact(block.contact)
					break
			}
		}
	}

	if (node.type !== 'gallery' && node.blocks) {
		for (const block of node.blocks) {
			switch (block.type) {
				case 'fullprojects':
					revalidate = 60 * 30
					await prefetchFullProjects(block, lang)
					break
				case 'fullarticles':
					revalidate = 60 * 30
					await prefetchFullArticles(block, lang)
					break
				case 'fullcalendar':
					revalidate = 60 * 30
					await prefetchFullCalendar(block, lang)
					break
				case 'fullcontacts':
					if (block.contacts) {
						block.contacts.forEach((contact) => obfuscateContact(contact))
					}
					break
				case 'contacts':
					if (block.contacts) {
						block.contacts.forEach((contact) => obfuscateContact(contact))
					}
					break
			}
		}
	}

	return {
		props: {
			node,
			general,
			appEnv: (process.env.NEXT_PUBLIC_APP_ENV as AppEnvType) || null
		},
		revalidate
	}
}

const obfuscateContact = (contact) => {
	contact.phone = obfuscate(contact.phone)
	contact.email = obfuscate(contact.email)
	if (contact.sf_user) contact.sf_user.email = obfuscate(contact.sf_user.email)
}
